import React, { memo } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

import PageWrapper from '../components/PageWrapper'
import SectionTitle from '../components/SectionTitle'
import Card from '../components/Card'
import SEO from '../components/SEO'
// import SectionTags from '../components/SectionTags/WorkSectionTags'
import SectionTags from '../components/SectionTags'
import Pagination from '../components/Pagination'

const ProjectList = ({ data, pageContext }) => {
  const _renderCards = () => {
    if (data && data.allWpWorkItem && data.allWpWorkItem.edges && data.allWpWorkItem.edges.length > 0) {
      return data.allWpWorkItem.edges.map(({ node }) => {
        const { slug, title, acf_work: { description, mainImage, projectDate, tags } } = node
        return (
          <Card
            key={slug}
            type="work"
            slug={slug}
            title={title}
            excerpt={description}
            image={mainImage}
            tags={tags}
            date={projectDate}
          />
        )
      })
    } else {
      return <p className="text-center">まだ記事は投稿されていません。</p>
    }
  }

  const _renderPagination = () => {
    if (pageContext && pageContext.currentPage) {
      const type = pageContext.tag ? `work/tags/${pageContext.tag}` : 'work'
      return <Pagination type={type} currentPage={pageContext.currentPage} maxPages={pageContext.numPages}/>
    }
    return null
  }
  return (
    <PageWrapper>
      <SEO title="制作物" />
      <section className="workPage">
        <SectionTitle title="Work" subtitle="制作物" />
        <p className="jp-accent text-center pageDescription">お客様にご了承いただいた制作物の一部を載せています。</p>
        <SectionTags tags={data.allWpWorkTag.edges} activeTag={pageContext.tag} type="work"/>
        <div className="cardsContainer flex">
          { _renderCards() }
        </div>
        { _renderPagination() }
        <SectionTags tags={data.allWpWorkTag.edges} activeTag={pageContext.tag} type="work" spOnly/>
      </section>
    </PageWrapper>
  )
}
export default memo(ProjectList)

ProjectList.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array
}

export const pageQuery = graphql`
  query workListQuery($tag: String, $skip: Int, $limit: Int){
    allWpWorkItem(
      limit: $limit
      skip: $skip
      sort: {fields: acf_work___projectDate, order: DESC}
      filter: {acf_work: {workTags: {elemMatch: {slug: {eq: $tag}}}}}
    ){
      edges{
        node{
          id
          slug
          title
          acf_work {
            mainImage {
              title
              altText
              sourceUrl
            }
            description
            projectDate
            tags: workTags {
                name
                slug
                termTaxonomyId
                description
              }
          }
        }
      }
    }

    allWpWorkTag {
        edges {
          node {
            id
            slug
            name
          }
        }
      }
  }
`
